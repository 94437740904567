export enum LocalStorageEnum {
  token = "_t",
  token_expires_at = "_tea",
  user_id = "_u",
  user_profile = "_uf",
  user_firstname = "_ufn",
  user_lastname = "_uln",
  user_role = "_ur",
  refresh_token = "_rft",
  permissions = "_up",
  lang = "lang",
  username = "username",
  language = "language",
  organization = "_org",
  organization_id = "_oid",
  setting = "_setting",
  use_meeting = "_um",
  use_mission = "_umis",
  use_leave = "_ul",
  use_document = "_udoc",
  use_mobile = "_umob",
  use_announcement = "_uann",
  use_file = "_uf",
  use_report = "_urp",
  use_softDelete = "_usd",
  use_authorization = "_uath",
  manage_authorization = "_math",
  has_special_display = "_hsd",
  use_meeting_request = "_umr",
  use_meeting_schedule = "_ums",
  use_leader = "_ul",
  show_tv_link = "_stl"
}
